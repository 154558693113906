import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PageLoaderService } from './page-loader.service';
import { API_URL,apiSigningKey,LOCAL_PAYMENT_API_URL, LOCAL_PAYMENT_PORT } from '../common/api';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
declare const CryptoJSAesJson;

@Injectable({ providedIn: 'root' })
export class UtilityService {   

  Orderstatus = new Subject();
  Orderuuid = new Subject();
  confirmationResult: any;
  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
    private pageLoaderService: PageLoaderService,
    private router: Router,
    private store: LocalStoreService,
    private modalController: ModalController,
    private translate: TranslateService,
    public alertController: AlertController,
  ) {
    
   }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     // eslint-disable-next-line @typescript-eslint/naming-convention
  //     'Content-Type':  'application/json',
  //     // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
  //     // eslint-disable-next-line @typescript-eslint/naming-convention
  //     'Accept-Language': this.store.getLanguageUuid()//'d12cc4046dbc53630c2ba3afcb18640c'
  //   })
  // };

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    const signingKey = apiSigningKey;
    const timestamp = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    const body = payload ? JSON.stringify(payload) : '';
    const data = `${timestamp}|${body}`;
    const signature = CryptoJS.HmacSHA256(data, signingKey).toString(CryptoJS.enc.Hex);
     let options: any = {
                            headers: new HttpHeaders({
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              'Content-Type':  'application/json',
                              // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              'Accept-Language': this.store.getLanguageUuid(),
                              'Accept-Language-Code': this.store.getLanguageCode()
                            })
                        }
    switch (method) {
      case 'get':
        return this.httpClient.get(API_URL + endPoint, options )
        .pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      case 'post':
        return this.httpClient.post(API_URL + endPoint, payload,options)
        .pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      case 'put':
        return this.httpClient.put(API_URL + endPoint, payload)
        .pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      case 'patch':
        return this.httpClient.patch(API_URL + endPoint, payload)
        .pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      case 'delete':
        return this.httpClient.delete(API_URL + endPoint, payload)
        .pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      default:
        return null;
    }
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2100,
      position: 'top',
    });
    toast.present();
  }


  onSuccess(message): void {
    this.pageLoaderService.pageLoader(false);//hide page loader
    this.pageLoaderService.setLoaderText('');//setting loader text empty
    //this.toastrManager.success(message, 'Success!'); //showing success toaster
    this.presentToast(message);
  }

  onError(message): void {
    this.pageLoaderService.setLoaderText('');//setting loader text
    this.pageLoaderService.pageLoader(false);//hide page loader
    //this.toastrManager.error(message, 'Error!');//showing error toaster message
    this.presentToast(message);
  }

  showPageLoader(message = ''): void {
    this.pageLoaderService.pageLoader(true,message);//show page loader
    if (message.length > 0) {
      this.pageLoaderService.setLoaderText(message);//setting loader text
    }
  }

  hidePageLoader(): void {
    this.pageLoaderService.pageLoader(false);//hide page loader
    this.pageLoaderService.setLoaderText('');//setting loader text
  }

  login(postedData): Observable<any> {
    // return this.httpClient.post('auth/login', postedData, { observe: 'response' })
    return this.httpClient.post('auth/login', postedData);
  }

  halt_maintenance(c){

  }

  payment_server: any;
  wait_for_payment(order_item_id: string, subtotal_in_dollar: number, id: string ) {
    // demux kiosk id=1 to port 3101, id=2 to port 3201 etc...
    // diff gateway executables will listen on these ports
    /*
    this.payment_server = "http://192.168.10.11:81";    
    if ( (id=='7') || (id=='8') )
    {
      this.payment_server = "http://localhost:7000";
    }
    if ( (id=='3') || (id=='4') )
    {
      this.payment_server = "http://192.168.10.11:7000";
    }
    */
    this.payment_server = LOCAL_PAYMENT_API_URL+":"+LOCAL_PAYMENT_PORT;

    if ( (id=='5') || (id=='6') )
    {
      subtotal_in_dollar = 1;
      if (id == '5') id = '1';
      if (id == '6') id = '2';
    }
    //subtotal_in_dollar = 1;
    console.log("dollars charged: "+ (subtotal_in_dollar).toString() );
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', LOCAL_PAYMENT_API_URL);

    return this.httpClient.post(
      this.payment_server + '/api/Adyen/PaymentRequest?amount='+(subtotal_in_dollar).toString()+'&Terminalid=T'+id+'&ServiceID='+order_item_id, 
      {},
      { headers, responseType: 'text'}
    );
  }



  showAlert(title: string, message: string, ID: string) {
    const alert = document.getElementById('custom-alert-'+ID);
    const alertTitle = document.getElementById('alert-title-'+ID);
    const alertMessage = document.getElementById('alert-message-'+ID);
  
    if (alert && alertTitle && alertMessage) {
      alertTitle.innerText = title;
      alertMessage.innerText = message;
      alert.classList.remove('hidden'); // Show the alert
    }
     
  }
  
  closeAlert(ID: string) {
    const alert = document.getElementById('custom-alert-'+ID);
    if (alert) {
      alert.classList.add('hidden'); // Hide the alert
    }
  }
  
  
  
}